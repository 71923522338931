<template>
  <div class="Profile__General__Page">
    <div class="Profile__General__Page__Header">
      <h1>הגדרות פרופיל</h1>
    </div>
    <div class="Profile__General__Page__Form">
      <div style="display: flex; gap: 20px" class="Profile__General__Page__Form__Field">
        <b-field label="פרופיל מנוהל">
          <b-switch v-model="model.IsManaged" @input="onChangeActive">
            {{ model.IsManaged ? "כן" : "לא" }}
          </b-switch>
        </b-field>
        <b-field v-if="isAdmin" label="הסבה לילית">
          <b-switch :disabled="!model.IsManaged" v-model="IsOnNightRunning" @input="onChangeNightRunning">
            {{ IsOnNightRunning ? "כן" : "לא" }}
          </b-switch>
        </b-field>
        <b-field v-if="isAdmin" label="אפשר ייבוא מריפורט">
          <b-switch :disabled="!model.IsManaged" v-model="model.AllowRePortImport" @input="onChangeAllowRePortImport">
            {{ AllowRePortImport ? "כן" : "לא" }}
          </b-switch>
        </b-field>
        <div v-if="isAutomationLoading">
          <b-loading :is-full-page="false" v-model="isAutomationLoading" :can-cancel="false" />
        </div>
      </div>
      <div style="display: flex; gap: 20px" class="Profile__General__Page__Form__Field">
        <b-field v-if="isAdmin" label="פרופיל מנוהל באוטומציה">
          <b-switch :disabled="!model.IsManaged" v-model="model.IsInAutomation" @input="onChangeAutomation">
            {{ model.IsInAutomation ? "כן" : "לא" }}
          </b-switch>
        </b-field>
        <b-field v-if="isAdmin" label="פרופיל מנוהל במילאון">
          <b-switch :disabled="!model.IsManaged" v-model="model.IsInMileon" @input="onChangeIsInMileon">
            {{ IsInMileon ? "כן" : "לא" }}
          </b-switch>
        </b-field>
        <b-field v-if="isAdmin" label="פרופיל מנוהל במיתר">
          <b-switch :disabled="!model.IsManaged" v-model="model.IsInMeitar" @input="onChangeIsInMeitar">
            {{ IsInMeitar ? "כן" : "לא" }}
          </b-switch>
        </b-field>
      </div>
      <div class="Profile__General__Page__Form__Field">
        <b-field label="נבדק לאחרונה בתאריך">
          <!-- <b-input :readOnly="true" v-model="model.LastCheckDate"></b-input> -->
          <div
            style="display: flex; align-items: center; align-items: center; padding: 0 10px; direction: ltr; min-width: 144px; border: 1px solid #cbd6e2;">
            {{ parseDate(model.LastCheckDate) }}</div>
          <b-button @click="updateCheckDate" :loading="isUpdatingCheckDate" type="is-primary">עדכן</b-button>
        </b-field>
        <b-field label="סיסמה למרכז הקנסות">
          <b-input type="password" v-model="model.FineCenterPassword" password-reveal>
          </b-input>
          <b-button @click="savePassword" :loading="isSavingPassword" type="is-primary">עדכן</b-button>
        </b-field>
        <b-field label="APP NAME">
          <b-input readonly style="max-width: 290px;" type="password" v-model="AppName" password-reveal>
          </b-input>
        </b-field>
        <b-field label="API KEY">
          <b-input readonly style="max-width: 290px;" type="password" v-model="ApiKey" password-reveal>
          </b-input>
        </b-field>
        <b-field label="SECRET KEY">
          <b-input readonly style="max-width: 290px;" type="password" v-model="ApiSecret" password-reveal>
          </b-input>
        </b-field>
      </div>
      <div class="Profile__General__Page__Form__Field">
        <h3>הגדרות מערכת ניתור</h3>
        <b-table :data="model.ProfileTrackingProviders" :bordered="true" :striped="true" :narrowed="false"
          :hoverable="false">
          <b-table-column centered field="Id" label="סוג" v-slot="props">
            <b-select v-model="props.row.Type.Id" placeholder="בחר סוג">
              <option :value="1">איתוראן</option>
              <option :value="2">פויינטר</option>
            </b-select>
          </b-table-column>
          <b-table-column centered field="UserName" label="שם משתמש" v-slot="props">
            <b-input v-model="props.row.UserName"></b-input>
          </b-table-column>
          <b-table-column centered field="Password" label="סיסמה" v-slot="props" width="200">
            <b-input type="password" v-model="props.row.Password" password-reveal>
            </b-input>
          </b-table-column>
          <b-table-column centered field="IsActive" label="פעיל" v-slot="props">
            <b-switch v-model="props.row.IsActive">
              {{ props.row.IsActive ? "כן" : "לא" }}
            </b-switch>
          </b-table-column>
          <b-table-column centered field="IsActive" label="" v-slot="props">
            <b-button :loading="isSavingProfileTracking" @click="updateProfileTracking(props.row)" type="is-primary">
              עדכן
            </b-button>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ProfileService from "@/services/ProfileService";
import moment from "moment";

export default {
  name: "ProfileSettings",
  data() {
    return {
      isLoading: false,
      isSavingPassword: false,
      isUpdatingCheckDate: false,
      isSavingProfileTracking: false,
      isAutomationLoading: false,
      IsOnNightRunning: null,
      AppName: null,
      ApiKey: null,
      ApiSecret: null,
      model: {
        IsManaged: null,
        FineCenterPassword: null,
        ProfileTrackingProviders: [],
        IsInAutomation: null,
        IsInMileon: null,
        IsInMeitar: null,
        AllowRePortImport: null,
        LastCheckDate: null
      },
    };
  },
  computed: {
    ...mapGetters("profile", ["getProfileSettings"]),
    IsManaged() {
      return this.$store.state.profile.Settings.IsManaged;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    IsInAutomation() {
      return this.$store.state.profile.Settings.IsInAutomation;
    },
    IsInMileon() {
      return this.$store.state.profile.Settings.IsInMileon;
    },
    IsInMeitar() {
      return this.$store.state.profile.Settings.IsInMeitar;
    },
    AllowRePortImport() {
      return this.$store.state.profile.Settings.AllowRePortImport;
    },
    LastCheckDate() {
      return this.$store.state.profile.Settings.LastCheckDate;
    },
  },
  created() {
    this.model = { ...this.model, ...this.getProfileSettings };
    ProfileService.getProfileAutomaticTransfer().then((res) => {
      this.IsOnNightRunning = res.data;
    });
    ProfileService.getProfileKeys().then((r) => {
      this.AppName = r.data[0].AppName;
      this.ApiKey = r.data[0].ApiKey;
      this.ApiSecret = r.data[0].ApiSecret;
    });
  },
  methods: {
    ...mapMutations("profile", ["setProfileSettings"]),
    updateProfileTracking(row) {
      this.isSavingProfileTracking = true;
      ProfileService.updateProfileTracking(row.Id, row)
        .then(() => {
          this.setProfileSettings({
            ProfileTrackingProviders: this.model.ProfileTrackingProviders,
          });
          this.showSuccessMessage();
        })
        .finally(() => {
          this.isSavingProfileTracking = false;
        });
    },
    savePassword() {
      this.isSavingPassword = true;
      ProfileService.setFineCenterPassword(this.model.FineCenterPassword)
        .then(() => {
          this.setProfileSettings({ FineCenterPassword: this.model.FineCenterPassword });
          this.isSavingPassword = false;
          this.showSuccessMessage();
        }
        );
    },
    updateCheckDate() {
      this.isUpdatingCheckDate = true;
      ProfileService.updateLastCheckDate()
        .then(() => {
          this.setProfileSettings({ LastCheckDate: moment(Date.now()) });
          this.isUpdatingCheckDate = false;
          this.showSuccessMessage();
        }
        );
    },
    parseDate(date) {
      if (date) return moment(date).format("DD/MM/YYYY HH:mm");
      return '';
    },
    showSuccessMessage() {
      this.$buefy.toast.open({
        type: "is-success",
        message: "נשמר בהצלחה!",
        duration: 4000,
      });
    },
    onChangeActive() {
      const value = this.model.IsManaged;
      this.$buefy.dialog.confirm({
        message: `האם אתה בטוח שברצונך לשנות סטטוס ל${value ? "פעיל" : "לא פעיל"
          }?`,
        onConfirm: () => {
          ProfileService.setIsManaged(value).then(() => {
            this.setProfileSettings({ IsManaged: value });
            this.showSuccessMessage();
          });
        },
        onCancel: () => {
          this.model.IsManaged = !value;
        },
        confirmText: "בטוח!",
        cancelText: "בטל",
      });
    },
    onChangeNightRunning() {
      const value = this.IsOnNightRunning;
      this.$buefy.dialog.confirm({
        message: `האם אתה בטוח שברצונך לשנות סטטוס של הסבה לילית ל${value ? "פעיל" : "לא פעיל"
          }?`,
        onConfirm: () => {
          this.isAutomationLoading = true;
          value ? ProfileService.addProfileAutomaticTransfer().then(() => {
            this.showSuccessMessage();
          }).catch(() => {
            this.IsOnNightRunning = !value;
            this.$buefy.toast.open({
              type: "is-danger",
              message: "קרתה תקלה!",
              duration: 4000,
            });
          }).finally(() => {
            this.isAutomationLoading = false;
          }) : ProfileService.deleteProfileAutomaticTransfer()
            .then(() => {
              this.showSuccessMessage();
            }).catch(() => {
              this.IsOnNightRunning = !value;
              this.$buefy.toast.open({
                type: "is-danger",
                message: "קרתה תקלה!",
                duration: 4000,
              });
            }).finally(() => {
              this.isAutomationLoading = false;
            });
        },
        onCancel: () => {
          this.IsOnNightRunning = !value;
        },
        confirmText: "בטוח!",
        cancelText: "בטל",
      });
    },
    onChangeAutomation() {
      const value = this.model.IsInAutomation;
      this.$buefy.dialog.confirm({
        message: `האם אתה בטוח שברצונך לשנות סטטוס ל${value ? "פעיל" : "לא פעיל"
          }?`,
        onConfirm: () => {
          this.isAutomationLoading = true;
          value ? ProfileService.addAutomation().then(() => {
            this.setProfileSettings({ IsInAutomation: value });
            this.showSuccessMessage();
          }).catch(() => {
            this.model.IsInAutomation = !value;
            this.$buefy.toast.open({
              type: "is-danger",
              message: "קרתה תקלה!",
              duration: 4000,
            });
          }).finally(() => {
            this.isAutomationLoading = false;
          }) : ProfileService.removeAutomation()
            .then(() => {
              this.setProfileSettings({ IsInAutomation: value });
              this.showSuccessMessage();
            }).catch(() => {
              this.model.IsInAutomation = !value;
              this.$buefy.toast.open({
                type: "is-danger",
                message: "קרתה תקלה!",
                duration: 4000,
              });
            }).finally(() => {
              this.isAutomationLoading = false;
            });
        },
        onCancel: () => {
          this.model.IsInAutomation = !value;
        },
        confirmText: "בטוח!",
        cancelText: "בטל",
      });
    },
    onChangeIsInMileon() {
      const value = this.model.IsInMileon;
      this.$buefy.dialog.confirm({
        message: `האם אתה בטוח שברצונך לשנות סטטוס ל${value ? "פעיל" : "לא פעיל"
          }?`,
        onConfirm: () => {
          this.isAutomationLoading = true;
          value ? ProfileService.addMileon().then(() => {
            this.setProfileSettings({ IsInMileon: value });
            this.showSuccessMessage();
          }).catch(() => {
            this.model.IsInMileon = !value;
            this.$buefy.toast.open({
              type: "is-danger",
              message: "קרתה תקלה!",
              duration: 4000,
            });
          }).finally(() => {
            this.isAutomationLoading = false;
          }) : ProfileService.removeMileon()
            .then(() => {
              this.setProfileSettings({ IsInMileon: value });
              this.showSuccessMessage();
            }).catch(() => {
              this.model.IsInMileon = !value;
              this.$buefy.toast.open({
                type: "is-danger",
                message: "קרתה תקלה!",
                duration: 4000,
              });
            }).finally(() => {
              this.isAutomationLoading = false;
            });
        },
        onCancel: () => {
          this.model.IsInMileon = !value;
        },
        confirmText: "בטוח!",
        cancelText: "בטל",
      });
    },
    onChangeIsInMeitar() {
      const value = this.model.IsInMeitar;
      this.$buefy.dialog.confirm({
        message: `האם אתה בטוח שברצונך לשנות סטטוס ל${value ? "פעיל" : "לא פעיל"
          }?`,
        onConfirm: () => {
          this.isAutomationLoading = true;
          value ? ProfileService.addMeitar().then(() => {
            this.setProfileSettings({ IsInMeitar: value });
            this.showSuccessMessage();
          }).catch(() => {
            this.model.IsInMeitar = !value;
            this.$buefy.toast.open({
              type: "is-danger",
              message: "קרתה תקלה!",
              duration: 4000,
            });
          }).finally(() => {
            this.isAutomationLoading = false;
          }) : ProfileService.removeMeitar()
            .then(() => {
              this.setProfileSettings({ IsInMeitar: value });
              this.showSuccessMessage();
            }).catch(() => {
              this.model.IsInMeitar = !value;
              this.$buefy.toast.open({
                type: "is-danger",
                message: "קרתה תקלה!",
                duration: 4000,
              });
            }).finally(() => {
              this.isAutomationLoading = false;
            });
        },
        onCancel: () => {
          this.model.IsInMeitar = !value;
        },
        confirmText: "בטוח!",
        cancelText: "בטל",
      });
    },
    onChangeAllowRePortImport() {
      const value = this.model.AllowRePortImport;
      this.$buefy.dialog.confirm({
        message: `האם אתה בטוח שברצונך לשנות סטטוס ל${value ? "פעיל" : "לא פעיל"
          }?`,
        onConfirm: () => {
          this.isAutomationLoading = true;
          value ? ProfileService.allowReport(true).then(() => {
            this.setProfileSettings({ AllowRePortImport: value });
            this.showSuccessMessage();
          }).catch(() => {
            this.model.AllowRePortImport = !value;
            this.$buefy.toast.open({
              type: "is-danger",
              message: "קרתה תקלה!",
              duration: 4000,
            });
          }).finally(() => {
            this.isAutomationLoading = false;
          }) : ProfileService.allowReport(false)
            .then(() => {
              this.setProfileSettings({ AllowRePortImport: value });
              this.showSuccessMessage();
            }).catch(() => {
              this.model.AllowRePortImport = !value;
              this.$buefy.toast.open({
                type: "is-danger",
                message: "קרתה תקלה!",
                duration: 4000,
              });
            }).finally(() => {
              this.isAutomationLoading = false;
            });
        },
        onCancel: () => {
          this.model.AllowRePortImport = !value;
        },
        confirmText: "בטוח!",
        cancelText: "בטל",
      });
    },
  },
  watch: {
    IsManaged() {
      this.model = { ...this.model, ...this.getProfileSettings };
    },
    IsInAutomation() {
      this.model = { ...this.model, ...this.getProfileSettings };
    },
    IsInMileon() {
      this.model = { ...this.model, ...this.getProfileSettings };
    },
    IsInMeitar() {
      this.model = { ...this.model, ...this.getProfileSettings };
    },
    LastCheckDate() {
      this.model = { ...this.model, ...this.getProfileSettings };
    },
    IsOnNightRunning() {
      this.model = { ...this.model, ...this.getProfileSettings };
    },
  },
};
</script>
<style lang="scss" scoped>
.Profile__General__Page {
  padding: 20px 40px;

  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;

    h1 {
      font-size: 30px;
    }
  }

  .Profile__General__Page__Form {
    margin-top: 20px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      .switch,
      .control {
        direction: ltr;
      }

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }

        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }

    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }

        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
